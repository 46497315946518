<template>
  <div>
    <div id="sub-categories-dropdown-container">
      <!-- Our triggering (target) element -->
      <button
        id="categories-dropdown"
        ref="button"
        @click="togglePop"
        class="filter-button shadow"
      >
        {{ subCatName }} <i class="fa fa-chevron-down"></i>
      </button>

      <!-- Our popover title and content render container -->
      <!-- We use placement 'auto' so popover fits in the best spot on viewport -->
      <!-- We specify the same container as the trigger button, so that popover is close to button -->
      <b-popover
        target="categories-dropdown"
        triggers="click"
        :show.sync="popoverShow"
        placement="rightbottom"
        container="sub-categories-dropdown-container"
        ref="popover"
        @show="onShow"
        @hidden="onHidden"
      >
        <div style="position: absolute; right: 0px; bottom: 0">
          <b-button @click="onClose" class="close" aria-label="Close">
            <span class="d-inline-block" aria-hidden="true">&times;</span>
          </b-button>
        </div>
        <div class="row">
          <div class="col-12 filter-column">
            <template v-if="fetchingRecords">
              <div
                class="w-100 h-100"
                style="display: grid; place-items: center"
              >
                <b-spinner variant="primary"></b-spinner>
              </div>
            </template>
            <ul class="list-group" v-if="!fetchingRecords">
              <li
                class="list-group-item"
                v-for="catItem in catList"
                :key="catItem.subCategoryId"
                @click="getSubCats(catItem.subCategoryId, catItem.name)"
                :class="catItem.active"
              >
                {{ catItem.name }}
              </li>
            </ul>
          </div>
        </div>
        <div class="d-flex justify-content-center">
          <b-pagination
            v-model="currentPage"
            :total-rows="rows"
            :per-page="perPage"
            :current-page="currentPage"
            @change="changePage"
          ></b-pagination>
        </div>
      </b-popover>
    </div>
  </div>
</template>

<script>
import { GET_SUB_CATEGORIES } from "@/core/services/store/categories.module";
import { mapState, mapGetters } from "vuex";
export default {
  props: {
    subCatName: String,
    subCatId: String,
    catId: String
  },
  data() {
    return {
      popoverShow: false,
      perPage: 10,
      currentPage: 1
    };
  },
  mounted() {
    this.$store.dispatch(GET_SUB_CATEGORIES, {
      categoryId: this.catId
    });
  },
  computed: {
    ...mapState({
      fetchingRecords: function(state) {
        return state.categories.subCategoriesFetching;
      },
      rows: function(state) {
        return state.categories.subCategoriesCount;
      },
      catList: function(state) {
        let arr = [];
        state.categories.subCategories.map(function(item) {
          const tableItem = {
            name: item.name,
            subCategoryId: item._id,
            active: !item.archived
          };
          arr.push(tableItem);
        });
        return arr;
      }
    })
  },
  methods: {
    ...mapGetters([
      "getAllSubCats",
      "getSubCategoriesCount",
      "getSubCategoriesFetching"
    ]),
    getSubCats(id, name) {
      this.$emit("updateProducts", { id, name });
      this.popoverShow = false;
    },
    changePage(val) {
      this.$store.dispatch(GET_SUB_CATEGORIES, {
        categoryId: this.catId,
        limit: [
          (val - 1) * this.perPage,
          (val - 1) * this.perPage + this.perPage
        ]
      });
    },
    togglePop: function() {
      this.popoverShow = !this.popoverShow;
    },
    activeRow(val) {
      this.rowActive = val;
    },
    /*   showRow(val) {
      return this.rowActive === val ? true : false;
    }, */
    onClose() {
      this.popoverShow = false;
    },

    onShow() {},

    onHidden() {
      this.focusRef(this.$refs.button);
    },
    focusRef(ref) {
      this.$nextTick(() => {
        this.$nextTick(() => {
          (ref.$el || ref).focus();
        });
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.filter-button {
  padding: 5px 10px;
  text-align: center;
  cursor: pointer;
  border-radius: 20px;
  color: #fff;
  font-size: 14px;
  background: transparentize($color: #dc1828, $amount: 0.5);
  display: flex;
  align-items: center;
  i {
    color: #fff;
    font-size: 12px;
    padding: 5px 5px 0px 5px;
  }
  &:hover {
    background: transparentize($color: #dc1828, $amount: 0.65);
  }
}
.popover {
  left: -5px !important;
  top: -15px !important;
  max-width: 500px;
  width: 500px;
  border-radius: 0;

  ::v-deep.row {
    margin-right: 0;
    margin-left: 0;
  }

  ::v-deep.arrow {
    display: none;
  }

  ::v-deep .popover-body {
    height: 100%;
    padding: 0;
    .filter-column {
      height: 200px;
      padding-right: 0;
      padding-left: 0;
      overflow-y: scroll;
      &:nth-child(1) {
        border-right: 1px solid rgba(0, 0, 0, 0.5);
      }
      .btn-group {
        border-radius: 0;
      }
      label.btn-secondary {
        background: #fff;
        border: 0;
        border-radius: 0;
        max-height: 40px;
        &.focus {
          background: #fff;
          outline: #dc1828;
        }
        &.active {
          background: transparentize($color: #dc1828, $amount: 0.9);
        }
      }
      &::-webkit-scrollbar-track {
        -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
        background-color: #f5f5f5;
      }
      &::-webkit-scrollbar {
        width: 6px;
        background-color: #f5f5f5;
      }
      &::-webkit-scrollbar-thumb {
        background-color: #dc1828;
      }
      .list-group {
        border-radius: 0;
        .list-group-item {
          cursor: pointer;
          border-radius: 0;

          &.active {
            color: #3f4254;
            background: rgba(0, 0, 0, 0.1);
          }
          &:hover {
            background: rgba(0, 0, 0, 0.15);
          }
        }
      }
    }
  }
}
</style>
